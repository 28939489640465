import { Component } from 'react';

import './Search.css';

class Header extends Component {
    render() {
        return (
            <div className="searchBox">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5">
                            <input type="text" placeholder="Search By Futsal Name" className="form-control"/>
                        </div>
                        <div className="col-sm-5">
                            <input type="text" placeholder="Search By Location" className="form-control"/>
                        </div>
                        <div className="col-sm-2 text-left">
                            <button className="btn btn-primary"><span className="fa fa-search"></span> Search</button>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
        );
    }
}

export default Header;