import { Component } from 'react';

import './Header.css';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="logo float-left">
                                <h2>Futsal Nepal</h2>
                            </div>
                            <div className="registerSection float-right">
                                <a href="login" className="btn btn-sm btn-primary">Login</a>
                                <a href="register" className="btn btn-sm btn-secondary">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;