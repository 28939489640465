import React, { Component } from 'react';
import './FutsalList.css';
import Futsal from '../Futsal/Futsal';

class FutsalList extends Component {
    props: any;
    state: any;
    constructor(props: any) {
        super(props);
        this.state = { greetings: ['Jim', 'Sally', 'Bender', 'Jim', 'Sally', 'Bender', 'Jim', 'Sally', 'Bender', 'Jim', 'Sally', 'Bender','Jim', 'Sally', 'Bender'] };
        this.renderSingleFulsal = this.renderSingleFulsal.bind(this);
    }

    render() {
        return (
            <div className="FutsalList">
                <div className="container">
                    <div className="row">
                        {this.renderSingleFulsal()}
                    </div>
                </div>
            </div>
        );
    }

    renderSingleFulsal() {
        return this.state.greetings.map((name1: any) => (
            <Futsal key={name1} name={name1}/>
        ));
    }
}
export default FutsalList;