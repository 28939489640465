import { Component } from 'react';
import './Futsal.css';

class HelloWorld extends Component {
    props: any;
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="col-sm-12 col-md-6">
                <div className="futsal text-left">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="futsalAvatar">
                                <img src="/img/futsal-thumbnail.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="futsalName"><h4>Khwopa Futsal and Training Center</h4></div>
                            <div className="address">
                                <span className="fa fa-map-marker"></span> Suryabinayak, Bhaktapur
                            </div>
                            <div className="costPerHour">
                                <span>Rs. 1000/hr</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HelloWorld;