import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Search from './components/Search/Search';
import Footer from './components/Footer/Footer';
import FutsalList from './components/FutsalList/FutsalList';
import './App.css';

const App = () => {
    return (
        <div className="wrapper">
            <Header />
            {/* <Banner /> */}
            <Search />
            <FutsalList />
            <Footer />
        </div>
    );
};

export default App;