import { Component } from 'react';

import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            Copyright &copy; {new Date().getFullYear()} | Prajwal Bati
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;